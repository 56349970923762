import scrollama from "../plugin/scrollama";
import {tns} from "../plugin/tiny-slider/src/tiny-slider.module";
import ShuffleText from "../plugin/shuffle-text/build/shuffle-text.module";

import {EventDispatcher} from "utility/event-dispatcher";
import {EVENT} from "../const";
import {MouseStalker} from "ui/mouse-stalker";
import {ToggleNav} from "ui/toggle-nav";
import {VideoController} from "ui/video-controller";
import {FirstHeight} from "ui/first-height";
import {RandomDelay} from "ui/random-delay";
import {ElementPosition} from "ui/element-position";

export class UiHomeManager {
	#deviceIsPC;
	#changeColorPosY;
	#bodyTag = document.querySelector("body");
	#bgColor = "black";

	#mouseStalker;
	#videoController;
	#baseScroller;
	#shuffleTextScroller

	constructor() {
		this.#deviceIsPC = document.querySelector("html").classList.contains("pc");

		//高さ設定
		FirstHeight.setProperty();

		//ハンバーガーメニュー
		new ToggleNav();

		//マウスに追従する
		this.#mouseStalker = new MouseStalker("a.detail");

		//ABOUTの表示をランダムに
		RandomDelay.setRandomTransitionDelay("#about .message h3 span", 0.6);
		RandomDelay.setRandomTransitionDelay("#about .message .text span", 1.5, 0.6);

		//NEWSのスライダー
		const newsSlider = tns({
			container: "#hero .news ol",
			axis: "vertical",
			autoplay: true,
			autoplayHoverPause: true,
			controls: false,
			nav: false,
			autoplayTimeout: 3000,
		});

		//動画の再生・停止
		this.#videoController = new VideoController();

		if (this.#deviceIsPC) {
			//PCのみマウスカーソルの位置で動画をコントロール
			const works = document.querySelectorAll("section.work");
			for (let i = 0; i < works.length; i++) {
				const work = works[i];
				work.addEventListener("mouseenter", () => {
					this.#videoController.worksPlay(work);
				});
				work.addEventListener("mouseleave", () => {
					this.#videoController.worksStop(work);
				});
			}
		}

		//スクロールに応じた表示チェック
		this.#baseScroller = scrollama();
		this.#baseScroller
				.setup({step: "article, section", offset: 0.5, once: false, debug: false})
				.onStepEnter((response) => {
					response.element.classList.add("inview");

					//ビデオのコントロール
					if (response.element.id === "hero") {
						this.#videoController.heroPlay();
					} else if (response.element.classList.contains("work") && !this.#deviceIsPC) {
						//タッチデバイスはスクロール位置で動画再生
						this.#videoController.worksPlay(response.element);
					}

					//WebGLの変形
					if (response.element.dataset.morph === "logo") {
						EventDispatcher.dispatch(EVENT.MORPH_TO_LOGO);
					} else if (response.element.dataset.morph === "sphere") {
						EventDispatcher.dispatch(EVENT.MORPH_TO_SPHERE);
					}
				})
				.onStepExit((response) => {
					response.element.classList.remove("inview");

					//ビデオのコントロール
					if (response.element.id === "hero") {
						this.#videoController.heroStop();
					}
					if (response.element.classList.contains("work")) {
						this.#videoController.worksStop(response.element);
					}
				});

		//テキストシャッフル
		const shuffles = document.querySelectorAll(".shuffle");
		const shuffleTexts = [];
		for (let i = 0; i < shuffles.length; i++) {
			shuffles[i].dataset.shufflenum = i;
			//テキストのシャッフル表示
			const st = new ShuffleText(shuffles[i]);
			st.duration = 800;
			st.sourceRandomCharacter = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890+-*/%&!?";
			shuffleTexts[i] = st;
		}

		this.#shuffleTextScroller = scrollama();
		this.#shuffleTextScroller
				.setup({step: ".shuffle", offset: 1, once: false})
				.onStepEnter((response) => {
					const num = response.element.dataset.shufflenum;
					const st = shuffleTexts[num];
					if (!st.isRunning)
						st.start();
				});

		//背景色を変える位置
		this.#changeColorPosY = ElementPosition.getY("#works");
	}

	/*
	 * フレーム更新
	 */
	update() {
		this.#mouseStalker.update();
	}
	
	/*
	 * スクロール時の処理
	 */
	scroll() {
		//スクロールに応じて背景色を変更
		if (window.scrollY + window.innerHeight * 0.5 > this.#changeColorPosY && this.#bgColor === "black") {
			EventDispatcher.dispatch(EVENT.COLOR_TO_WHITE);
			this.#bodyTag.classList.remove('bg-black');
			this.#bodyTag.classList.add('bg-white');
			this.#bgColor = 'white';
		} else if (window.scrollY + window.innerHeight * 0.5 < this.#changeColorPosY && this.#bgColor === "white") {
			EventDispatcher.dispatch(EVENT.COLOR_TO_BLACK);
			this.#bodyTag.classList.remove('bg-white');
			this.#bodyTag.classList.add('bg-black');
			this.#bgColor = 'black';
		}
	}

	/*
	 * リサイズ時の処理
	 */
	resize() {
		FirstHeight.setProperty();
		this.#baseScroller.resize();
		this.#shuffleTextScroller.resize();
		this.#changeColorPosY = ElementPosition.getY("#works");
	}

}
